body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: 'Monofonto';
  src:
    local('Monofonto'),
    url(./fonts/monofonto.ttf) format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Oswald-Medium';
  src:
    local('Oswald-Medium'),
    url(./fonts/Oswald-Medium.ttf) format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Open-Sans-Regular';
  src:
    local('Open-Sans-Regular'),
    url(./fonts/Open-Sans-Regular.ttf) format('truetype');
  font-display: swap;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

.swiper-button-prev {
  color: #6ec6ca !important;
}

.swiper-button-next {
  color: #6ec6ca !important;
}

.nm-time-saver-popup {
  animation-name: animation-time-saver-popup;
  animation-duration: 1000ms;
  animation-iteration-count: infinite;
  opacity: 0;
}

@keyframes animation-time-saver-popup {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.nm-time-saver-button {
  animation-name: nm-pulse-animation;
  animation-duration: 1000ms;
  animation-iteration-count: infinite;
}

@keyframes nm-pulse-animation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

@keyframes nm-pulse-animation-2s-delay {
  0% {
    opacity: 1;
  }
  25% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

.nm-all-in-one-place-active {
  animation-name: nm-pulse-animation-2s-delay;
  animation-duration: 5000ms;
  animation-iteration-count: infinite;
}

.nm-all-in-one-place-pending {
  animation-name: nm-pulse-animation-2s-delay;
  animation-duration: 5000ms;
  animation-delay: 1000ms;
  animation-iteration-count: infinite;
}

.nm-all-in-one-place-down {
  animation-name: nm-pulse-animation-2s-delay;
  animation-duration: 5000ms;
  animation-delay: 2000ms;
  animation-iteration-count: infinite;
}
.nm-all-in-one-place-news {
  animation-name: nm-pulse-animation-2s-delay;
  animation-duration: 5000ms;
  animation-delay: 3000ms;
  animation-iteration-count: infinite;
}
.nm-all-in-one-place-down2 {
  animation-name: nm-pulse-animation-2s-delay;
  animation-duration: 5000ms;
  animation-delay: 4000ms;
  animation-iteration-count: infinite;
}

@layer components {
  .nm-default-page {
    @apply flex justify-center items-center h-full text-center m-auto;
  }
  .nm-default-page-small-content {
    @apply flex justify-center items-center text-center h-[80%] m-auto;
  }
  .nm-default-page-heading {
    @apply font-bold font-heading text-4xl lg:text-5xl text-main-letters text-center;
  }
  .nm-default-page-heading-white-text {
    @apply text-4xl xl:text-5xl font-heading;
  }

  .nm-glass-forms-box-wrapper {
    @apply bg-main-footerLinks bg-opacity-40 p-6 2xl:p-20 border border-main-letters rounded-3xl;
  }
  .nm-glass-forms-header {
    @apply my-4 lg:my-8 font-bold font-heading text-4xl text-main-letters text-center;
  }
}
